/**
 * CookieYes, GDPR Cookie Consent (Cookie Law Info)
 * https://wordpress.org/plugins/cookie-law-info/
 */

export const cookieyes = {

	/**
	 * Get the CookieYes cookies
	 *
	 * @returns {{statistics: boolean, marketing: boolean, preferences: boolean, necessary: boolean}|null}
	 */
	getConsent: () => {

		/**
		 * Process the new cookieyes-consent cookie that contains all the information
		 */

		// let consent = "consentid:cnpZSGg2SDZzWXJodEc3TWF3UTdKVVM3UmtKWHc3SEk,consent:yes,action:yes,necessary:yes,functional:yes,analytics:no,performance:yes,advertisement:yes"
		let consent = wpm.getCookie("cookieyes-consent")

		if (consent) {

			// This cookie has the following structure:
			// consentid:cnpZSGg2SDZzWXJodEc3TWF3UTdKVVM3UmtKWHc3SEk,consent:yes,action:yes,necessary:yes,functional:yes,analytics:no,performance:yes,advertisement:yes
			// First split the comma-separated values into an array
			// Then split each array item into a key value pair. The key and the value are separated by a colon.
			// Then create an object from the key value pairs.
			// Then console.log the object to see what it looks like.

			consent = consentToJson(consent)

			// console.log("CookieYes consent", consent)

			return {
				statistics : consent.analytics ?? true,
				marketing  : consent.advertisement ?? true,
				preferences: consent.functional ?? true,
				necessary  : consent.necessary ?? true,
			}
		}

		/**
		 * Process the old cookieyes-* cookies
		 *
		 * @param cookieNames
		 * @returns {string|string|boolean}
		 */

		const getCookieValue = (cookieNames) => cookieNames.find(name => wpm.getCookie(name)) || null

		const cookieNames = {
			statistics : [
				"cookielawinfo-checkbox-analytics",
				"cookielawinfo-checkbox-analytiques",
				"cookieyes-analytics",
			],
			marketing  : [
				"cookielawinfo-checkbox-advertisement",
				"cookielawinfo-checkbox-performance",
				"cookielawinfo-checkbox-publicite",
				"cookieyes-advertisement",
			],
			preferences: [
				"cookielawinfo-checkbox-functional",
				"cookielawinfo-checkbox-preferences",
				"cookieyes-functional",
			],
			necessary  : [
				"cookielawinfo-checkbox-necessary",
				"cookielawinfo-checkbox-necessaire",
				"cookieyes-necessary",
			],
		}

		let data = {
			statistics : getCookieValue(cookieNames.statistics) === null ? undefined : getCookieValue(cookieNames.statistics) ? true : false,
			marketing  : getCookieValue(cookieNames.marketing) === null ? undefined : getCookieValue(cookieNames.marketing) ? true : false,
			preferences: getCookieValue(cookieNames.preferences) === null ? undefined : getCookieValue(cookieNames.preferences) ? true : false,
			necessary  : getCookieValue(cookieNames.necessary) === null ? undefined : getCookieValue(cookieNames.necessary) ? true : false,
		}

		// if at least one of the data key values is not undefined, return the data object
		if (Object.values(data).some(value => value !== undefined)) {
			return data
		}

		return null
	},

	loadExternalScripts: () => {

		window.addEventListener("cookieyes_consent_update", (e) => {

			let data = {
				statistics : e.detail.accepted.includes("analytics"),
				marketing  : e.detail.accepted.includes("advertisement"),
				preferences: e.detail.accepted.includes("functional"),
				necessary  : e.detail.accepted.includes("necessary"),
			}

			pmw.consent.api.updateSelectively(data)
		})
	},
}


const consentToJson = (consent) => {

	consent = consent.split(",")

	let consentObj = {}

	consent.forEach((item) => {
		let [key, value] = item.split(":")
		consentObj[key]  = value
	})

	consent = consentObj

	// make every yes or no to boolean
	for (let key in consent) {
		if (consent[key] === "yes") {
			consent[key] = true
		} else if (consent[key] === "no") {
			consent[key] = false
		}
	}

	return consent
}
